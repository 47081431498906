cs.ns("app.ui.trait.modaldialogs");
app.ui.trait.modaldialogs.ctrl = cs.trait({
    dynamics: {
        modalDialog: null
    },
    protos:   {

        setup () {
            this.base();

            this.subscribeForChildEvent("openModalDialog", (ev, params, acceptCB, cancelCB) => {
                ev.propagation(false);
                this.openModalDialog({
                    title:    params.title,
                    dialog:   params.dialog,
                    data:     params.data || {},
                    acceptCB: acceptCB,
                    cancelCB: cancelCB
                });
            });

            this.subscribeForChildEvent("closeModalDialog", (/*ev*/) => {
                this.closeModalDialog();
            });

        },

        openModalDialog (args) {
            if (!this.modalDialog) {
                this.modalDialog = this.view.create("modalDialog", args.dialog);
                this.modalDialog.call("initializeDialog", args);
                this.enableAndShowComponent(this.modalDialog);
            } else {
                this.modalDialog.call("focus");
            }
        },

        closeModalDialog () {
            if (this.modalDialog) {
                this.modalDialog.property("ComponentJS:state-auto-increase", false);
                this.modalDialog.state({
                    state: "created",
                    sync:  true,
                    func:  () => {
                        this.modalDialog.destroy();
                        this.modalDialog = null;
                    }
                });

            }
        }

    }
});